<template>
  <div class="block without-footer map">
    <div class="header">現地マップ</div>
    <div class="body" > 
        <Map
            :zoom="zoom"
            :markers="markers"
            :fitness="fitness"
            :center="center"
            style="width: 100%; height: 100%"
        ></Map>
    </div>
</div>
</template>
  
<script>
    import Map from "@/components/Map.vue";

    export default {
      name: 'BlockMap',
      components: {
        Map
      }
      ,computed: {
        center: function() {
          return {
            lat: this.item.position.lat,
            lng: this.item.position.lng,
          };
        },
        markers: function() {
          let data = [];
          let element = {
            id: this.item.id,
            name: this.item.name,
            lat: this.item.position.lat,
            lng: this.item.position.lng,
            props: this.$store.getters.getAlertColor(this.item)
          };
          data.push(element);
          return data;
        },
      }
      ,
      created() {
        // console.log("created") 
    
        }
      ,
      mounted() {
        // console.log("mounted") 
        }
      ,
      props: {
        item: {
          type: Object,
          default: () => []
        }
      },
      data() { 
        return {
          // Google Map
          zoom: 15,
          fitness: false,
        }
      }
      ,methods : {
        
      }
    }
</script>
<style lang="scss">
.block.map { 
  .body { height: 330px; padding:0; overflow: hidden; }
}

</style>