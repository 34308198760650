<template>
	<v-container class="place">
		<PlaceHeader :item="item"></PlaceHeader>
		<PlaceLocalMenu :item="item"></PlaceLocalMenu>

		<div class="main-body">
			
			<v-row>
				<v-col class="left" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
					<BlockCurrentLevel :item="item"></BlockCurrentLevel>
				</v-col>
				<v-col class="right" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
					<BlockMap :item="item"></BlockMap>
				</v-col>
			</v-row>

			<ChartWaterHistory :item="item"></ChartWaterHistory>
			
			<BlockPhotos :item="item"></BlockPhotos>
			
			<BlockInfo :item="item"></BlockInfo>
		</div>
		
	</v-container>
</template>

<script>
import PlaceHeader from "@/components/PlaceHeader.vue";
import PlaceLocalMenu from "@/components/PlaceLocalMenu.vue";
import BlockMap from "@/components/BlockMap.vue";
import BlockCurrentLevel from "@/components/BlockCurrentLevel.vue"
import BlockPhotos from "@/components/BlockPhotos.vue"
import BlockInfo from "@/components/BlockInfo.vue"
import ChartWaterHistory from "@/components/ChartWaterHistory.vue";
import { VueLoading } from 'vue-loading-template'

export default {
	components: {
		PlaceHeader
		,PlaceLocalMenu
		,BlockCurrentLevel
		,ChartWaterHistory
		,BlockMap
		,BlockPhotos
		,BlockInfo
	},
	computed: {
	},
	mounted() {
		if (!this.item) {
			this.$router.push({ name: "Home" }).catch(() => {});
		}
		this.scrollTop();
	},
	created() {
		this.item = this.$store.getters.getPlaceByIndex(this.$route.params.id);
		// this.$store.dispatch("setWaterlevelData", { index: this.item.id });
		// this.$store.dispatch("setPowerlevelData", { index: this.item.id });
		// console.log("Place created - waterlevel: " + JSON.stringify(this.$store.state.places.waterlevelData[this.item.id]));
		// this.$refs.chart.reloadChart("ts", this.$store.state.waterlevelData[this.item.id].ts);
		// this.$refs.chart.reloadChart("level", this.$store.state.waterlevelData[this.item.id].level);
		// this.$refs.chart.updateChart();
	},
	methods: {
		scrollTop: function() {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
	},
	data() {
		return {
			item: null
		};
	},
};
</script>

<style lang="scss">

</style>
