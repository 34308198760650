<template>
  <div class="block without-footer info2">
    <div class="header">基本情報</div>
    <div class="body" > 
      
      <div>
        <table>
          <tr>
            <td>管理番号:</td><td>{{ item.id }}</td>
          </tr>
          <tr>
            <td>エリア:</td><td>{{ item.district }}</td>
          </tr>
          <tr>
            <td>名称:</td><td>{{ item.name }}</td>
          </tr>
          <tr>
            <td>所在地:</td><td>{{ item.address }}</td>
          </tr>
          <tr>
            <td>緯度:</td><td>{{ item.position.lat }}</td>
          </tr>
          <tr>
            <td>経度:</td><td>{{ item.position.lng }}</td>
          </tr>
          <tr>
            <td>水位計電池電圧:</td><td>{{  item.waterlevel_sensor_power  }}v</td>
          </tr>
          <tr>
            <td>雨量計電池電圧:</td><td>{{  item.rain_sensor_power  }}v</td>
          </tr>
          <!--
          <tr>
            <td>設置台数:</td><td>2</td>
          </tr>
          -->
        </table>
      </div>

      <div>
        <table>
          <tr>
            <td>現在水位:</td><td>{{ this.waterLevelFormat(item.waterlevel.level[item.waterlevel.level.length-1]) }}</td>
          </tr>
          <tr>
            <td>{{ item.alertThresholdNames[3] }}:</td><td>{{  this.waterLevelFormat(item.alertThresholds[0])  }}</td>
          </tr>
          <tr>
            <td>{{ item.alertThresholdNames[2] }}:</td><td>{{  this.waterLevelFormat(item.alertThresholds[1])  }}</td>
          </tr>
          <tr>
            <td>{{ item.alertThresholdNames[1] }}:</td><td>{{  this.waterLevelFormat(item.alertThresholds[2])  }}</td>
          </tr>
          <tr>
            <td>{{ item.alertThresholdNames[0] }}:</td><td>{{  this.waterLevelFormat(item.alertThresholds[3])  }}</td>
          </tr>
          <tr>
            <td>常時満水位:</td><td>{{  "±0.00m (" + item.fulllevel + "m)" }}</td>
          </tr>
          <!--
          <tr>
            <td>左岸高さ:</td><td>{{  this.waterLevelFormat(item.leftShoreHeight)  }}</td>
          </tr>
          <tr>
            <td>右岸高さ:</td><td>{{  this.waterLevelFormat(item.rightShoreHeight)  }}</td>
          </tr>
          -->
        </table>
      </div>

    </div>

</div>
</template>
  
<script>

    export default {
      name: 'BlockInfo',
      components: {
        
      }
      ,computed: {
        
      }
      ,
      created() {
        // console.log("created") 
    
        }
      ,
      mounted() {
        // console.log("mounted") 
        }
      ,
      props: {
        item: {
          type: Object,
          default: () => []
        }
        
      },
      data() { 
        return {
          
        }
      }
      ,methods : {
        waterLevelFormat: function(n) {
          let s = ''
          if(n>0){
            // s = n.toFixed(2)
            s = '+' + n.toFixed(2)
          } else {
            s = n.toFixed(2)
          }
          s += 'm'
          return s
        }
      }
    }
</script>
<style lang="scss">
.block.info2 { 
  .body {  padding:30px 30px; overflow: hidden; display: flex; 
      > div {
        width: 50%;
        table {
          tr {
            td {
              padding: .3em; font-size: 18px; 
              &:first-child { text-align: right; }
            }
          }
        }
      }
      @media screen and (max-width:720px){
        flex-direction: column;
        > div { width: 100%;
          table {
            width: 100%;
            tr {
              td:first-child {width: 50%;}
            }
          }
        }
      }
      
  }
}

</style>