<template>
  <div class="block water-history">
    <div class="header">水位の遷移</div>
    <div class="body" @mousemove="onMouseMove($event, $refs.linechart)">
      <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
      ref="linechart"
    /><div class="overlay"></div>
    </div>
    <div class="footer">
      <div>
        <!--
        <button @click="onClickRange($refs.linechart, 12 )">12時間</button>
        <button @click="onClickRange($refs.linechart, 24 )">1日</button>
        <button @click="onClickRange($refs.linechart, 24*3 )">3日</button>
        <button @click="onClickRange($refs.linechart, 24*7 )">7日</button>
        <button @click="onClickRange($refs.linechart, 24*30 )">30日</button>
        -->
      </div>
      <div>
        <button @click="onClickZoomOut($refs.linechart)">-</button>
        <button @click="onClickZoomIn($refs.linechart)">+</button>
        <button @click="onClickResetZoom($refs.linechart)">リセット</button>
      </div>
    </div>
  </div>
</template>
  
<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import zoomPlugin from 'chartjs-plugin-zoom';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  TimeScale,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
  Filler
  ,zoomPlugin
)

export default {
  name: 'WaterHistoryLineChart',
  components: {
    LineChartGenerator
  }
  ,
  computed:{
  }
  ,
  created() {
    // console.log("created") 

	}
  ,beforeMount(){
    // console.log("beforeMount") 
  }
  ,
  async mounted() {
    this.chartData = {
        labels: this.item.waterlevel.ts
    };
    this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false
        ,
        layout: {
            padding: {
              right: function(){ 
                let padding = 120 + 10
                const ww = window.innerWidth
                if(ww < 720){
                  padding = 0
                }
                return padding
              }
              ,top: function(){ 
                let padding = 30
                const ww = window.innerWidth
                if(ww < 720){
                  padding = 130
                }
                return padding
              }
            }
        }
        ,
        scales: {
          x: {
            ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              callback: function(val) { // , index
                // let num_ticks = this.ticks.length
                let s = ''
                // if(index % (num_ticks-1) === 0 || index % Math.floor(num_ticks / 20) === 0 ){
                let ds = new Date( this.getLabelForValue(val) )
                s = (ds.getMonth() + 1) + '/' + ds.getDate() + ' ' + ('0' + ds.getHours()).slice(-2) + ':' + ('0' + ds.getMinutes()).slice(-2); 
                // }
                return s;
              },
              color: 'black',
            }
          }
          ,
          water_level: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            min: Math.min(...this.item.waterlevel.level.concat(this.item.alertThresholds)) - 0.1,
            max: Math.max(...this.item.waterlevel.level.concat(this.item.alertThresholds)) + 0.1
            // max: this.item.alertThresholds[ this.item.alertThresholds.length-1 ] + 0.5
          },
        }
        ,plugins: {
          annotation: { // This is needed to stop error "RangeError: Maximum call stack size exceeded"
              annotations: [

              ]
          },
            tooltip: {
                callbacks: {
                  title: function(context){
                    let title = new Date(context[0].label);
                    return title.getFullYear() + '/' + (title.getMonth() + 1) + '/' + title.getDate() + ' ' + ('0' + title.getHours()).slice(-2) + ':' + ('0' + title.getMinutes()).slice(-2)
                  }
                  ,
                  label: function(context) {
                      let label = context.dataset.label || '';

                      if (label) {
                          label += ' : ';
                      }
                      if (context.parsed.y !== null) {
                        if (context.dataset.label == "降雨量") {
                            label += new Intl.NumberFormat('en-US', { style: 'decimal', maxDigits: 2 }).format(context.parsed.y) + ' mm';
                          } else {
                            label += new Intl.NumberFormat('en-US', { style: 'decimal', maxDigits: 2 }).format(context.parsed.y) + ' m';
                          }
                      }
                      return label;
                  }
                }
            }
            ,
            legend: {
              display: false
              ,position: 'right'
            }
            ,
            zoom:{
              limits:{
                // x : { min: 20, max: 100 }
              }
              ,
              zoom : {
                wheel : {
                  enabled: true
                  ,speed : 0.01
                  
                }
                ,mode : 'x'
              }
              ,pan : {
                  enabled: true
                  ,mode : 'x'
                }
            }
        }
      }
      if (Object.keys(this.item.rain).length) {
        this.chartOptions.scales["rain"] =  {
            type: 'linear',
            display: true,
            position: 'left',
        }
        this.chartData.datasets = [
          {
            label: '降雨量'
            ,fill : false
            ,borderColor: "#1560FF"
            ,backgroundColor: "#1560FF"
            ,borderWidth: 2
            ,pointRadius: 0.8
            ,data: this.item.rain.level
            ,yAxisID: 'rain'
          }
          ,
          {
            label: '水位'
            ,fill : "start"
            ,backgroundColor: context => {
              const chart = context.chart
              const { ctx, chartArea, scales } = chart
              if(!chartArea) { return null }
              return this.getGradient(ctx, chartArea, scales)
            }
            ,borderColor: "#3e95cd"
            ,borderWidth: 1
            ,data: this.item.waterlevel.level
            ,yAxisID: 'water_level'
          }
        ]
      } else {
        this.chartData.datasets = [
          {
            label: '水位'
            ,fill : "start"
            ,backgroundColor: context => {
              const chart = context.chart
              const { ctx, chartArea, scales } = chart
              if(!chartArea) { return null }
              return this.getGradient(ctx, chartArea, scales)
            }
            ,borderColor: "#3e95cd"
            ,borderWidth: 1
            ,data: this.item.waterlevel.level
            ,yAxisID: 'water_level'
          }
        ]
      }
  }
  ,
  props: {
    chartId: {
      type: String,
      default: 'chart-water-history'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    // plugins: {
    //   type: Array,
    //   default: () => []
    // }
    // ,
    item: {
      type: Object,
      default: () => []
    }
  },
  data() { 
    return {
      chart: null,
      chartData: {},
      chartOptions: {},
      legend_width: 120
      ,
      
        plugins: [
          {
            // 特殊なレジェンドの作成
            id: 'custom_legends',

            beforeDraw: (function (chart) {
              
              try{

                const scales = chart.scales
                const chartArea = chart.chartArea
                const thresholds = this.item.alertThresholds
                const c = this.$store.getters.getColors

                let position ='right'
                let w = this.legend_width
                const h = 50
                let fontsize = 24

                const ww = window.innerWidth
                if(ww <= 440){
                  position = 'top'
                  fontsize = 16
                } else if(ww < 720){
                  position = 'top'
                  fontsize = 22
                }
                let ctx = chart.ctx;
                
                if(position == 'right'){
                  
                  let y = 30
                  
                  const xpos_0 = chartArea.left + chartArea.width 
                  const xpos_1 = chart.width - w

                  for(let i=5; i>1; i-- ){
                    
                    const ypos = scales.water_level.getPixelForValue( thresholds[i-2] )

                    ctx.fillStyle = c[i].color;
                    ctx.fillRect(chart.width - w, y, w, h);
                    
                    ctx.fillStyle = c[i].text_color;
                    ctx.font = "bold " +fontsize*0.8+ "px sans-serif";
                    ctx.textAlign = "center";
                    ctx.fillText( this.item.alertThresholdNames[this.item.alertThresholdNames.length - (i - 1)], chart.width  - w/2, y+20);
                    ctx.font = "bold " +fontsize+ "px sans-serif";
                    ctx.fillText( "+" + this.waterLevelFormat(thresholds[i-2]), chart.width  - w/2, y+45);
                    
                    
                    ctx.beginPath () ;
                    ctx.strokeStyle = c[i].color;
                    ctx.lineWidth = 0.5 ;
                    ctx.moveTo( chartArea.left, ypos ) ;
                    ctx.lineTo( xpos_0, ypos ) ;
                    ctx.stroke() ;

                    ctx.beginPath () ;
                    ctx.strokeStyle = c[i].color;
                    ctx.lineWidth = 2 ;
                    
                    ctx.moveTo( xpos_0, ypos ) ;
                    ctx.lineTo( xpos_1, y + h/2 )
                    
                    ctx.stroke() ;
                    
                    y += h+5
                  }
                  y +=  10
                  ctx.fillStyle = c[1].color;
                  ctx.fillRect(chart.width - w, y, w/2, h/2);
                  ctx.fillStyle = c[1].text_color;
                  ctx.font = "bold 14px sans-serif";
                  ctx.textAlign = "left";
                  ctx.fillText( c[1].name, chart.width  - w * 0.45, y+18);
                  y +=  30
                  // ctx.fillStyle = c[0].color;
                  // ctx.fillRect(chart.width - w, y, w/2, h/2);
                  // ctx.fillStyle = c[0].text_color;
                  // ctx.font = "bold 14px sans-serif";
                  // ctx.textAlign = "left";
                  // ctx.fillText( c[0].name, chart.width  - w * 0.45, y+18);

                  // y +=  30
                  //降雨量
                  if (Object.keys(this.item.rain).length) {
                    ctx.fillStyle = "#1560FF";
                    ctx.fillRect(chart.width - w, y + 10, w/2, 5);
                    ctx.fillStyle = "#1560FF";
                    ctx.font = "bold 14px sans-serif";
                    ctx.textAlign = "left";
                    ctx.fillText( "降雨量", chart.width  - w * 0.45, y+18);
                    //ctx.setLineDash([]);
                  }
                } else if(position == 'top'){

                  let x = 0
                  w = (chart.width / 4 ) - 3.5
                  let y = 0
                  const y_h = 50

                  ctx.lineWidth = 10 ;
                  ctx.strokeStyle = this.$store.getters.getAlertColor(this.item).color;
                  ctx.strokeRect(x+5, y+5, chart.width-10, 40);

                  ctx.textAlign = 'left';
                  ctx.font = "normal 16px sans-serif";
                  ctx.fillText('現在',15, 30 );
                  
                  ctx.textAlign = 'center';
                  ctx.font = "bold 24px sans-serif";
                  ctx.fillText( this.waterLevelFormat(this.item.waterlevel.level[this.item.waterlevel.level.length-1]), chart.width*0.4, 35 );
                  ctx.textAlign = 'right';
                  ctx.font = "normal 24px sans-serif";
                  ctx.fillText( this.$store.getters.getAlertColor(this.item).name, chart.width -15, 35 );

                  y = 55
                  for(let i=5; i>1; i-- ){
                    
                    // const ypos = scales.water_level.getPixelForValue( thresholds[i-2] )

                    ctx.fillStyle = c[i].color;
                    ctx.fillRect(x, y, w, y_h);
                    
                    ctx.fillStyle = c[i].text_color;
                    ctx.font = "bold " +fontsize*0.8+ "px sans-serif";
                    ctx.textAlign = "center";
                    ctx.fillText( this.item.alertThresholdNames[this.item.alertThresholdNames.length - (i - 1)], x + w/2, y+20);
                    ctx.font = "bold " +fontsize+ "px sans-serif";
                    ctx.fillText( "+" + this.waterLevelFormat(thresholds[i-2]), x + w/2, y+45);

                    x += w+5

                  }
                }

                // 単位
                ctx.textAlign = 'left';
                ctx.fillStyle = "#555";
                ctx.font = "normal 14px sans-serif";
                ctx.fillText('水位(m)', position=='right'? chart.width - w - 30 : chart.width - 50, position=='right'? 14 : 120 );
                ctx.restore();

                if (Object.keys(this.item.rain).length) {
                  ctx.textAlign = 'left';
                  ctx.fillStyle = "#555";
                  ctx.font = "normal 14px sans-serif";
                  ctx.fillText('降雨量(mm)', 0, position=='right'? 14 : 120 );
                  ctx.restore();
                }
              } catch( e){
                  console.log(e)
              }
            }).bind(this)
          }
        ]
    }
  }
  ,methods : {
    // update_chart(){
    //   this.$refs.linechart.getCurrentChart().update()
    // }
    // ,
    onClickRange(e,r){
      console.log(e,r)
    }
    ,
    onClickZoomIn(e){
      let chart = e.getCurrentChart()
      if(chart.getZoomLevel() < 10){
        chart.zoom( 1.2 , 'x')
      }
    }
    ,
    onClickZoomOut(e){
      let chart = e.getCurrentChart()
      chart.zoom( 0.8 , 'x')
    }
    ,
    onClickResetZoom(e){
      let chart = e.getCurrentChart()
      chart.resetZoom()
    }
    ,
    onMouseMove(e, c){
      let chart = c.getCurrentChart()
      let area = chart.chartArea
      if(area.left -10 < e.offsetX && e.offsetX < area.left + area.width +10 && area.top - 10  < e.offsetY && e.offsetY < area.top+area.height + 10){
        document.body.classList.add('can-zoom')
      } else {
        document.body.classList.remove('can-zoom')
      }
    }
    ,
    getGradient(ctx, chartArea, scales){ 
      const thresholds = this.item.alertThresholds
      const c = this.$store.getters.getColors
      const gradientBg = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom)
      // console.log('--')
      // console.log(scales.x.getValueForPixel( chartArea.left+100 ))
      const v5 =  scales.water_level.getPixelForValue(thresholds[3], 0)
      const v4 =  scales.water_level.getPixelForValue(thresholds[2], 0)
      const v3 =  scales.water_level.getPixelForValue(thresholds[1], 0)
      const v2 =  scales.water_level.getPixelForValue(thresholds[0], 0)
      const v1 =  scales.water_level.getPixelForValue(0, 0)

      // console.log(v1,v2)
      const r5 = ( v5 - chartArea.top) / chartArea.height
      const r4 = ( v4 - chartArea.top) / chartArea.height
      const r3 = ( v3 - chartArea.top) / chartArea.height
      const r2 = ( v2 - chartArea.top) / chartArea.height
      const r1 = ( v1 - chartArea.top) / chartArea.height
      
      if( r1 < 0 ){
        gradientBg.addColorStop(0, c[0].color )
      } else if( r2 < 0 ){
        gradientBg.addColorStop(0, c[1].color )
      } else if( r3 < 0 ){
        gradientBg.addColorStop(0, c[2].color )
      } else if( r4 < 0 ){
        gradientBg.addColorStop(0, c[3].color )
      } else if( r5 < 0 ){
        gradientBg.addColorStop(0, c[4].color )
      } else {
        gradientBg.addColorStop(0, c[5].color )
      }

      if( r5 >= 1 ){
        gradientBg.addColorStop(1, c[5].color )
      } else if( r4 >= 1 ){
        gradientBg.addColorStop(1, c[4].color )
      } else if( r3 >= 1 ){
        gradientBg.addColorStop(1, c[3].color )
      } else if( r2 >= 1 ){
        gradientBg.addColorStop(1, c[2].color )
      } else if( r1 >= 1 ){
        gradientBg.addColorStop(1, c[1].color )
      }  else {
        gradientBg.addColorStop(1, c[0].color )
      }

      if(1 >= r5 && r5 >= 0){
        gradientBg.addColorStop(r5, c[5].color )
        gradientBg.addColorStop(r5, c[4].color )
      }
      if(1 >= r4 && r4 >= 0){
        gradientBg.addColorStop(r4, c[4].color )
        gradientBg.addColorStop(r4, c[3].color )
      }
      if(1 >= r3 && r3 >= 0){
        gradientBg.addColorStop(r3, c[3].color )
        gradientBg.addColorStop(r3, c[2].color )
      }
      if(1 >= r2 && r2 >= 0){
        gradientBg.addColorStop(r2, c[2].color )
        gradientBg.addColorStop(r2, c[1].color )
      }
      if(1 >= r1 && r1 >= 0){
        gradientBg.addColorStop(r1, c[1].color )
        gradientBg.addColorStop(r1, c[0].color )
      }

      return gradientBg
    }
  }
}
</script>
<style lang="scss">
.block.water-history {
  .body { padding-top:10px; }
}
</style>