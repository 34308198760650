<template>
  <div class="block without-footer photos">
    <div class="header">現地画像</div>
    <div class="body" > 
      <Flicking :options="{ renderOnlyVisible: true, align:' 5%' }">
        <div v-for="item in list" class="flicking-panel" :key="item.name" @click="onClickPhoto(item.img)">
          <img :src=item.img />
          <img src="~@/assets/icon_enlarge.svg" class="icon" />
          <div class="name">  {{item.name}} </div>
          <div>  {{item.time}} </div>
        </div>
      </Flicking>
      
    </div>
    <div class="modal" v-if="showModal" @click="closeModal()" >
      <div class="box"  >
        <img :src=modal_src />
      </div>
    </div>
</div>
</template>
  
<script>

    export default {
      name: 'BlockPhotos',
      components: {
        
      }
      ,computed: {
        
      }
      ,
      created() {
        // console.log("created") 
    
        }
      ,
      mounted() {
        // console.log("mounted") 
        }
      ,
      props: {
        item: {
          type: Object,
          default: () => []
        }
        
      },
      data() { 
        return {
          showModal: false
          ,modal_src : ''
          ,list: [
            { img:'/img/img001.jpg', name: '現地画像1', time: '2022.12.28 9:15 更新' }
            ,{ img:'/img/img002.jpg', name: '現地画像2', time: '2022.12.28 9:15 更新' }
          ]
        }
      }
      ,methods : {
        onClickPhoto(s){
          this.showModal = true
          this.modal_src = s
        }
        ,closeModal(){
          this.showModal = false;
        }
      }
    }
</script>
<style lang="scss">
.block.photos { 
  .body {  padding:30px 0; overflow: hidden; 
    .flicking-panel { width: 300px; max-width: 85vw; margin-right: 10px; position: relative; 
      img { width: 100%; border-radius: 10px; position: relative; cursor:pointer; 
        &.icon { 
          width: 30px; height: 30px; position: absolute; top: 10px; right: 10px; border-radius: 0; background-color: rgba(255,255,255,.7);
        }
      }
      .name { font-size: 20px; }
    }
  }
}

</style>